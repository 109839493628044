import React from 'react';
import './App.css';

function App() {
  return (
      <div>
          <header className="header header-transparent" id="header-main">
              <nav className="navbar navbar-main navbar-expand-lg navbar-transparent navbar-dark bg-dark" id="navbar-main">
                  <div className="container px-lg-0">
                      <a className="navbar-brand mr-lg-5" href="/">
                          <img alt="Image placeholder" src="img/cb-logo-white.png" id="navbar-logo" />
                      </a>
                      <button className="navbar-toggler pr-0" type="button" data-toggle="collapse"
                              data-target="#navbar-main-collapse" aria-controls="navbar-main-collapse" aria-expanded="false"
                              aria-label="Toggle navigation">
                          <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="collapse navbar-collapse" id="navbar-main-collapse">
                          <ul className="navbar-nav align-items-lg-center ml-lg-auto">
                              <li className="nav-item mr-0">
                                  <a className="nav-link d-lg-none" href="mailto:demo@clientbucket.com?subject=Demo Request&body=Hello Team Clientbucket
                I would love a demo of Clientbucket.

                Thank you,
                ">
                                      Request a demo</a>

                                  <a href="mailto:demo@clientbucket.com?subject=Demo Request&body=Hello Team Clientbucket
                I would love a demo of Clientbucket.

                Thank you,
                " className="btn btn-sm btn-outline-warning btn-icon rounded-pill d-none d-lg-inline-flex"
                                     data-toggle="tooltip" data-placement="left" title="Complete a request form">
                                      <span className="btn-inner--text">Request a demo</span>
                                  </a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </nav>
          </header>

          <div className="main-content">


              <section className="slice slice-lg bg-gradient-dark" data-offset-top="#header-main"
                       style={{paddingTop: "147.188px"}}>
                  <div className="bg-absolute-cover bg-size--contain d-flex align-items-center">
                      <figure className="w-100 d-none d-lg-block">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1016px" height="732px" viewBox="0 0 1016 732" className="injected-svg svg-inject">
                              <g id="Page-6" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <g id="Circles" transform="translate(508.000000, 366.000000) scale(-1, 1) translate(-508.000000, -366.000000) ">
                                      <circle id="Oval-4-Copy-3" fill="#48B5AC" cx="942.5" cy="378.5" r="6.5"></circle>
                                      <circle id="Oval-4-Copy-5" fill="#476EF0" cx="560" cy="11" r="11"></circle>
                                      <circle id="Oval-4" fill="#51E9B4" cx="635" cy="613" r="26"></circle>
                                      <circle id="Oval-4-Copy" fill="#2A4BB9" cx="1011" cy="454" r="5"></circle>
                                      <circle id="Oval-4-Copy-2" fill="#158DFF" cx="281.5" cy="725.5" r="6.5"></circle>
                                      <circle id="Oval-4-Copy-4" fill="#51E9B4" cx="911.5" cy="129.5" r="11.5"></circle>
                                      <circle id="Oval-4-Copy-9" fill="#51E9B4" cx="15" cy="500" r="15"></circle>
                                      <circle id="Oval-4-Copy-8" fill="#2A4BB9" cx="71" cy="454" r="5"></circle>
                                      <circle id="Oval-4-Copy-6" fill="#2E96FB" cx="14.5" cy="244.5" r="6.5"></circle>
                                  </g>
                              </g>
                          </svg>
                      </figure>
                  </div>
                  <div className="container position-relative zindex-100">
                      <div className="row row-grid justify-content-around align-items-center">
                          <div className="col-lg-8">
                              <div className="pt-6 text-center">
                                  <h1 className="text-white mb-3">
                                      Drive meaningful interactions for students, faculty, and staff
                                  </h1>
                                  <p className="lead text-white lh-180 w-75 mx-auto">
                                      Use Clientbucket to capture ideas, store and contextualize institutional knowledge,
                                      share and organize files, gain organizational insights, and improve satisfaction
                                      throughout the student, faculty, and staff lifecycles
                                  </p>
                                  <a href="mailto:demo@clientbucket.com?subject=Demo Request&body=Hello Team Clientbucket
                                    I would love a demo of Clientbucket.
                                    Thank you," className="btn btn-warning btn-icon rounded-pill hover-translate-y-n3 mt-4">
                                      <span className="btn-inner--text">Request a demo</span>
                                      <span className="btn-inner--icon"><i className="fas fa-angle-right"></i></span>
                                  </a>
                              </div>
                              <div className="mt-5 mb--100 d-none d-lg-block">
                                  <div className="frame-laptop swiper-js-container">
                                      <img alt="Image placeholder" src="img/macbook.png" className="img-fluid" />
                                      <div className="frame-inner">
                                          <div className="swiper-container h-100 swiper-container-horizontal"
                                               data-swiper-items="1" data-swiper-space-between="0" style={{cursor: "grab"}}>
                                              <div className="swiper-wrapper"
                                                   style={{transform: "translate3d(0px, 0px, 0px)"}}>
                                                  <div className="swiper-slide swiper-slide-active" style={{width: "569px"}}>
                                                      <div className="bg-img-holder top-0 right-0 col-12"
                                                           data-bg-size="cover"
                                                           style={{
                                                               backgroundImage: "url('img/student-qa.png')",
                                                               backgroundPosition: "initial", backgroundSize: "cover", opacity: 1, height:"100%"}}>
                                                          <img alt="Image placeholder" src="img/student-qa.png" />
                                                      </div>
                                                  </div>
                                              </div>
                                              <span className="swiper-notification" aria-live="assertive"
                                                    aria-atomic="true"></span></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <section className="slice bg-section-secondary delimiter-bottom">
                  <div className="container">
                      <div className="text-center">
                          <h3>
                              Join forward thinking leaders like you
                          </h3>
                          <div className="fluid-paragraph mt-3">
                              <p className="lead lh-180">Here are just a few of the institutions where we've partnered with innovative leaders to improve engagement and satisfaction for students, faculty, and staff</p>
                          </div>
                      </div>
                      <div>
                          <div className="client-group row justify-content-center">
                              <div className="client col-lg-2 col-md-3 col-4 py-3">
                                  <img src="img/schools/CCNY_logo.jpg" height="800" width="800"/>
                              </div>
                              <div className="client col-lg-2 col-md-3 col-4 py-3">
                                  <img src="img/schools/Abilene_Christian_University_wordmark.png" height="160" width="160"/>
                              </div>
                              <div className="client col-lg-2 col-md-3 col-4 py-3">
                                  <img src="img/schools/UTEP_box_mark.png" height="160" width="160"/>
                              </div>
                              <div className="client col-lg-2 col-md-3 col-4 py-3">
                                  <img src="img/schools/Norm-logo-cent_RGB.jpg" height="160" width="160"/>
                              </div>
                              <div className="client col-lg-2 col-md-3 col-4 py-3">
                                  <img src="img/schools/wsulogo_750xx2250-1266-0-117.jpg" height="160" width="160"/>
                              </div>
                              <div className="client col-lg-2 col-md-3 col-4 py-3">
                                  <img src="img/schools/umich.jpeg" height="160" width="160"/>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>


              <section className="slice slice-lg">
                  <div className="container">
                      <div className="row row-grid justify-content-around align-items-center">
                          <div className="col-lg-5">
                              <div className="">
                                  <h5 className=" h3">
                                      Bring ideas to life together
                                  </h5>
                                  <p className="lead my-4">
                                      Ideas become transformational when shared in the right context with the right people.
                                      Capture your best ideas with private or public notes. Keep institutional knowledge
                                      organized and searchable. Inspire your team to bring the best thinking and processes to
                                      life beautifully in their next great article or documentation.
                                  </p>
                              </div>
                          </div>
                          <div className="col-lg-6">
                              <img alt="Image placeholder" className="img-fluid img-center" src="img/cb-collaborate.png"/>
                          </div>
                      </div>
                  </div>
              </section>


              <section className="slice slice-lg">
                  <div className="container">
                      <div className="row row-grid justify-content-around align-items-center">
                          <div className="col-lg-5 order-lg-2">
                              <div className=" pr-lg-4">
                                  <h5 className=" h3">Personalized outreach</h5>
                                  <p className="lead my-4">
                                      Whether you’re sharing exclusive first drafts of an essay, opining about a book your
                                      book club is reading, sending monthly updates to investors, telling your contacts about
                                      your lab’s breakthrough, or reminding students to submit their assignments, the
                                      simplicity of broadcasts in Clientbucket lets you discover the power of personalized
                                      engagement.
                                  </p>
                              </div>
                          </div>
                          <div className="col-lg-6 order-lg-1">


                              <img src="img/cb-personalized.png" alt="Image placeholder" className="img-fluid img-center"/>

                          </div>
                      </div>
                  </div>
              </section>


              <section className="slice slice-lg">
                  <div className="container">
                      <div className="row row-grid justify-content-around align-items-center">
                          <div className="col-lg-5">
                              <div className="">
                                  <h5 className=" h3">
                                      Forms & Analytics
                                  </h5>
                                  <p className="lead my-4">
                                      Clientbucket’s forms and reporting tools help you create unique content types, polls,
                                      surveys, and quizzes. Reports help you facilitate meaningful interactions with insights
                                      about the needs and wants of your constituents––students, faculty, staff, and customers.
                                  </p>
                              </div>
                          </div>
                          <div className="col-lg-6">
                              <img src="img/forms_analytics .png" alt="Image placeholder" className="img-fluid img-center"/>
                          </div>
                      </div>
                  </div>
              </section>


              <div id="testimonials-testimonials-2" title="testimonials/testimonials-2.html">
                  <section className="slice slice-lg bg-dark-light">
                      <div className="container">
                          <div className="mb-5 text-center">
                              <h3 className="text-white mt-4">
                                  What users are saying about Clientbucket
                              </h3>
                          </div>
                          <div className="row justify-content-center">
                              <div className="col-lg-8">
                                  <div className="card">
                                      <div className="card-body">
                                          <div className="d-flex align-items-center">
                                              <div>
                                                  <img src="img/joann.jpeg" alt="Image placeholder" height="339" width="339"
                                                       className="avatar  rounded-circle"/>
                                              </div>
                                              <div className="pl-3">
                                                  <h5 className="h6 mb-0">Joann Huang</h5>
                                              </div>
                                          </div>
                                          <p className="mt-4 lh-180">
                                              Clientbucket is a very user-friendly tool for uploading and sharing resources.
                                              The tool gave me flexibility to share my files privately or publicly. My
                                              favorite feature is the gallery display for images because I can preview my
                                              uploaded images right away. I’m eager to make this tool a permanent feature in
                                              my workflow.
                                          </p>
                                      </div>
                                  </div>
                                  <div className="card">
                                      <div className="card-body">
                                          <div className="d-flex align-items-center">
                                              <div>
                                                  <img alt="Image placeholder" src="img/laura.jpeg" height="379" width="379"
                                                       className="avatar  rounded-circle"/>
                                              </div>
                                              <div className="pl-3">
                                                  <h5 className="h6 mb-0">Laura Simão</h5>
                                              </div>
                                          </div>
                                          <p className="mt-4 lh-180">
                                              What I like the most about Clientbucket is that it is a one stop place for all
                                              my needs as an academic. In it I can create and deliver courses, share, engage,
                                              and interact with my students in a very intuitive way, all while leveraging the
                                              platform to promote my work and increase my influence and thought leadership.
                                          </p>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
          <footer id="footer-main">
              <div className="footer footer-dark">
                  <div className="container">
                      <div className="row align-items-center justify-content-md-between py-4 delimiter-top">
                          <div className="col-md-6">
                              <div className="copyright text-sm font-weight-bold text-center text-md-left">
                                  &copy; 2018-2019 <a href="/" className="font-weight-bold" target="_blank">Clientbucket</a>.
                                  All rights reserved.
                              </div>
                          </div>
                          <div className="col-md-6">
                              <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">

                                  <li className="nav-item">
                                      <a className="nav-link" href="https://www.instagram.com/clientbucket/" target="_blank">
                                          <i className="fab fa-instagram"></i>
                                      </a>
                                  </li>

                                  <li className="nav-item">
                                      <a className="nav-link" href="https://twitter.com/clientbucket" target="_blank">
                                          <i className="fab fa-twitter"></i>
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
      </div>
  );
}

export default App;
